import styles from "../../styles/voltshare.module.scss"
import React, { useState } from "react"
import Layout from "../../../src/components/layout/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"
import withAppContext from "../../contexts/app.context"
import FaqSection from "../../components/faq-section/faq-section"
import FsLightbox from "fslightbox-react"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import PlaySvg from "../../components/svg/play"
import ExternalLinkSvg from "../../components/svg/external-link"

const VoltSharePage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "products__voltshare" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: { metaTitle, metaImageName, metaTwImageName },
    },
  } = queryResult

  const overviewVideo = "https://player.vimeo.com/video/416128163?autoplay=1"
  const storingVideo = "https://player.vimeo.com/video/414270339?autoplay=1"
  const enterpriseSharingVideo =
    "https://player.vimeo.com/video/414276511?autoplay=1"
  const docsLink = "/docs/about-f5-distributed-cloud/volt-share"

  const [videoLink, setVideoLink] = useState(overviewVideo)
  const [isVideoOpened, setVideoOpened] = useState(false)

  const openVideo = link => () => {
    setVideoLink(link)
    setVideoOpened(!isVideoOpened)
  }

  const onDownloadClick = () => {
    window.open("/download-latest-voltshare", "_blank")
  }

  const questions = [
    {
      title:
        "How is this technology different from solutions that claim end-to-end encryption?",
      expanded: false,
      answer: `
      End-to-end encryption is not difficult to achieve using a secure encryption algorithm if a sender and recipient(s) 
      securely share passwords or their PGP keys. However, neither of these are easy to share and keep track of, and do 
      not provide policy-based controls like time limits or revocation as needed. VoltShare does not use passwords for 
      recipients to decrypt their shared content — just a piece of information the sender already knows — e.g. recipient’s 
      email address.  In addition, VoltShare provides increased security and compliance through a broad set of policy-based 
      controls and revocation capabilities, as well as detailed logs and analysis. 
      <a href="${docsLink}" target="_blank">
        Explore Documentation 
        <img alt="Documentation link" src="/img/products/external-link-selected.svg" />
      </a>
      `,
    },
    {
      title:
        "Why should I use VoltShare if my cloud service provider (e.g. Dropbox or Office 365) or collaboration tools (e.g. Slack or Teams) are already encrypting all my data?",
      expanded: false,
      answer: `
      VoltShare does not replace any cloud storage or collaboration services.  It helps you more securely store or 
      transfer data so you’re not relying solely on these cloud providers for the privacy and security of your data 
      — essentially providing “layered security”. If these cloud services were ever compromised (externally or from within) 
      or forced to hand over data to a government agency, your data will not be compromised as it is independently encrypted 
      on top of your cloud provider’s underlying security service. 
      `,
    },
    {
      title:
        "What’s the difference between the free and paid versions of VoltShare?",
      expanded: false,
      answer: `
      The free version of VoltShare includes all the functionality required for data encryption, decryption and policy 
      enforcement - using the app or the APIs+SDK. The paid version includes additional functionality for enterprise-level 
      security, compliance audits and governance — including logs for encrypt/decrypt activity + associated policy-based 
      denials, audit logs, policy revocation or overrides, and single sign-on. The paid version also increases the rate 
      limits on the APIs.
      `,
    },
    {
      title: "How do I install VoltShare?",
      expanded: false,
      answer: `
      VoltShare can be easily downloaded from <a target="_blank" rel="noopener noreferrer" href="/download-latest-voltshare">here</a> and installed on 
      your personal computing device. Upon installation, the application will ask you to provide your existing Volterra 
      account credentials or sign-up with Volterra using your existing google, facebook, or github account. 
      `,
    },
    {
      title:
        "Can Volterra’s infrastructure, SaaS platform or teams get to see my data — either in clear or encrypted form?",
      expanded: false,
      answer: `
      No, VoltShare is unique in that Volterra’s cloud infrastructure and SaaS platform never see the actual content 
      shared or the encryption key that the sender’s VoltShare client app randomly generates to protect the data.  
      Everything remains on the sender’s and recipients’ local machines during the encryption and decryption process.
      `,
    },
    {
      title:
        "How does Volterra secure my data without transferring any data to its servers?",
      expanded: false,
      answer: `
      The VoltShare client software on your computing device encrypts data using the well-known cryptographic algorithms 
      AES-GCM (256-bit) and RSA (2048-bit). The encryption keys themselves are double-protected by other cryptographic 
      keys that are managed by a state-of-the-art key management system in Volterra’s SaaS platform and cloud infrastructure. 
      `,
    },
  ]

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/products/voltshare"}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      <section className={styles.header}>
        <div
          className={`container volterra-container d-flex flex-column flex-lg-row ${styles.header__container}`}>
          <div className={`row ${styles.header__wrapper}`}>
            <h1
              className={`${styles.header__title} ${styles.header__titleHighlighted}`}>
              VoltShare<span>&trade;</span>
            </h1>
            <h2 className={styles.header__title}>
              Secure Data Sharing. Easy. Free.
            </h2>
            <p className={styles.header__description}>
              Securely share your critical data while ensuring compliance.
              Simplify end-to-end encryption. Make your existing collaboration
              tools more secure.
            </p>
            <div className={styles.header__btnWrapper}>
              <button
                onClick={onDownloadClick}
                className={`btn btn-primary ${styles.header__btn}`}>
                Download beta
              </button>
              <button
                onClick={openVideo(overviewVideo)}
                className={`btn btn-primary ${styles.header__btn} ${styles.header__btnOutline}`}>
                <PlaySvg />
                Watch overview
              </button>
            </div>
          </div>
          <div className={styles.header__imageWrapper}>
            <div
              aria-label="VoltConsole image"
              className={styles.header__image}
              onClick={openVideo(overviewVideo)}>
              <PlaySvg />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.features}>
        <div
          className={`container volterra-container ${styles.features__container}`}>
          <div className={cblockStyles.cblock}>
            <div className={styles.feature}>
              <div className={styles.feature__body}>
                <h4 className={styles.feature__title}>
                  Securely share any sensitive file or data
                </h4>
                <p className={styles.feature__description}>
                  Send and store confidential information with end-to-end
                  encryption that eliminates the age-old problem of shared
                  secrets and public key mgmt. Maximize privacy of your
                  information. Protect against eavesdropping, compromised data
                  and errors.
                </p>
              </div>
              <picture>
                <source
                  srcSet="/img/products/voltshare-feature-1.png"
                  media="(min-width: 576px)"
                />
                <source
                  srcSet="/img/products/voltshare-feature-1-s.png"
                  media="(max-width: 575px)"
                />
                <img
                  className={`${styles.feature__image} ${styles.feature__image1}`}
                  src="/img/products/voltshare-feature-1.png"
                  alt="A graph showing how VoltShare works: enrypting and decrypting data shared via various platforms (Slack, Dropbox,SharePoint, Email...)"
                />
              </picture>
            </div>
          </div>

          <div className={cblockStyles.cblock}>
            <div className={`${styles.feature} ${styles.featureInverted}`}>
              <div className={styles.feature__body}>
                <h4 className={styles.feature__title}>
                  Policy-based access & control
                </h4>
                <p className={styles.feature__description}>
                  Control access using just an email address — nothing else —
                  and establish the policy you want to enforce and monitor. Then
                  confidently share information with an individual or groups of
                  people.
                </p>
              </div>
              <img
                className={`${styles.feature__image} ${styles.feature__image2}`}
                src="/img/products/voltshare-feature-2.png"
                alt="VoltShare application screenshot showing policies used to define access control"
              />
            </div>
          </div>

          <div className={cblockStyles.cblock}>
            <div className={styles.feature}>
              <div className={styles.feature__body}>
                <h4 className={styles.feature__title}>
                  Make your collaboration tools more secure
                </h4>
                <p className={styles.feature__description}>
                  Share your confidential information like you do today — Slack,
                  email, Dropbox, SharePoint, etc — knowing you have an extra
                  layer of encryption and security policy enforcement, as well
                  as no shared passwords or keys to worry about.
                </p>
              </div>
              <picture>
                <source
                  srcSet="/img/products/voltshare-feature-3.png"
                  media="(min-width: 576px)"
                />
                <source
                  srcSet="/img/products/voltshare-feature-3-s.png"
                  media="(max-width: 575px)"
                />
                <img
                  className={`${styles.feature__image} ${styles.feature__image3}`}
                  src="/img/products/voltshare-feature-3.png"
                  alt="A graph showing sharing encrypted data on various platforms (Slack, Dropbox,SharePoint, Email...)"
                />
              </picture>
            </div>
          </div>

          <div className={cblockStyles.cblock}>
            <div className={`${styles.feature} ${styles.featureInverted}`}>
              <div className={styles.feature__body}>
                <h4 className={styles.feature__title}>
                  Compliance & end-to-end control
                </h4>
                <p className={styles.feature__description}>
                  Simplify compliance through automated logs and auditability
                  for all shared data — including successful and failed
                  decryption attempts. Maintain enterprise-level control of
                  shared data through policy overrides.
                </p>
              </div>
              <picture>
                <source
                  srcSet="/img/products/voltshare-feature-4.png"
                  media="(min-width: 576px)"
                />
                <source
                  srcSet="/img/products/voltshare-feature-4-s.png"
                  media="(max-width: 575px)"
                />
                <img
                  className={`${styles.feature__image} ${styles.feature__image4}`}
                  src="/img/products/voltshare-feature-4.png"
                  alt="A graph showing compliance systems: GDPR, CCPA, PCI-DSS, HIPAA, SOC-2"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.videos} ${cblockStyles.cblock}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2>Use Case Videos</h2>
          </div>
        </div>
        <div
          className={`container volterra-container ${styles.videos__container}`}>
          <div className={styles.video}>
            <div
              onClick={openVideo(storingVideo)}
              aria-label="open Secure Storing and Sharing video"
              className={`${styles.video__preview} ${styles.video__preview1}`}>
              <PlaySvg className={styles.video__previewPlay} />
            </div>
            <div className={styles.video__body}>
              <h5 className={styles.video__title}>
                Secure Storing and Sharing
              </h5>
              <p className={styles.video__description}>
                An overview of how to use the VoltShare app to securely share or
                store data with your favorite collaboration tool like Slack or
                Dropbox.
              </p>
              <a
                className={styles.video__link}
                href="/docs/how-to/volt-share/secure-data"
                target="_blank">
                Demo Documentation
                <span className={styles.video__linkIcon}>
                  <ExternalLinkSvg />
                </span>
              </a>
            </div>
          </div>
          <div className={styles.video}>
            <div
              onClick={openVideo(enterpriseSharingVideo)}
              aria-label="open Enterprise Sharing and Governance video"
              className={`${styles.video__preview} ${styles.video__preview2}`}>
              <PlaySvg className={styles.video__previewPlay} />
            </div>
            <div className={styles.video__body}>
              <h5 className={styles.video__title}>
                Enterprise Sharing and Governance
              </h5>
              <p className={styles.video__description}>
                An overview of VoltShare's enterprise secure collaboration and
                governance capabilities that help meet compliance, get
                visibility, and clear audits easily.
              </p>
              <a
                className={styles.video__link}
                href="/docs/how-to/volt-share/enterprise-sharing-and-governance"
                target="_blank">
                Demo Documentation
                <span className={styles.video__linkIcon}>
                  <ExternalLinkSvg />
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.questions}>
        <div className={`container ${styles.questions__container}`}>
          <FaqSection props={{ questions, faqLink: "/docs/faqs/vs-faq" }} />
        </div>
      </section>

      <section className={styles.demo}>
        <div className={`container ${styles.volterraContainerCustom}`}>
          <div className="row">
            <div className="col-sm-12">
              <div
                className={`${styles.demo__wrapper} ${styles.demo__wrapperCustom}`}>
                <h2 className={styles.demo__title}>Ready to start?</h2>
                <p className={styles.demo__description}>
                  Request a demo or talk to our technical sales team to answer
                  your questions and explore your needs.
                </p>
                <div className={styles.demo__btnWrapper}>
                  <button
                    aria-label={"Download"}
                    onClick={onDownloadClick}
                    className={styles.demo__button}>
                    Download beta
                  </button>
                  <button
                    aria-label="Watch overview"
                    onClick={openVideo(overviewVideo)}
                    className={`${styles.demo__button} ${styles.demo__buttonOutline}`}>
                    Watch overview
                  </button>
                </div>
                <div className={styles.demo__image} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            src={videoLink}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
    </Layout>
  )
}

export default withAppContext(VoltSharePage)
