import React from "react"

const ExternalLinkSvg = () => (
  <svg width="14px" height="14px" viewBox="0 0 14 14">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="updated-copy-2"
        transform="translate(-762.000000, -4258.000000)"
        fill="#A1A7C0">
        <path
          d="M765.5,4258 C766.144332,4258 766.666667,4258.52233 766.666667,4259.16667 C766.666667,4259.811 766.144332,4260.33333 765.5,4260.33333 L765.5,4260.33333 L764.333333,4260.33333 L764.333333,4269.66667 L773.666667,4269.66667 L773.666667,4268.5 C773.666667,4267.85567 774.189001,4267.33333 774.833333,4267.33333 C775.477666,4267.33333 776,4267.85567 776,4268.5 L776,4268.5 L776,4269.66667 C776,4270.95533 774.955331,4272 773.666667,4272 L773.666667,4272 L764.333333,4272 C763.044669,4272 762,4270.95533 762,4269.66667 L762,4269.66667 L762,4260.33333 C762,4259.04467 763.044669,4258 764.333333,4258 L764.333333,4258 Z M774.833333,4258 C775.477666,4258 776,4258.52233 776,4259.16667 L776,4259.16667 L776,4263.59171 C776,4263.74642 775.938542,4263.89479 775.829146,4264.00419 C775.60134,4264.23199 775.231994,4264.23199 775.004188,4264.00419 L775.004188,4264.00419 L773.324833,4262.32483 L769.824833,4265.82483 C769.36929,4266.28038 768.63071,4266.28038 768.175167,4265.82483 C767.719624,4265.36929 767.719624,4264.63071 768.175167,4264.17517 L768.175167,4264.17517 L771.675167,4260.67517 L769.995812,4258.99581 C769.886416,4258.88642 769.824958,4258.73804 769.824958,4258.58333 C769.824958,4258.26117 770.086125,4258 770.408291,4258 L770.408291,4258 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
)

export default ExternalLinkSvg
